<template>
  <div>
    <div class="newsListDetail_bg">
      <img src="../../static/image/newsList_bg.png" />
    </div>
    <div class="newsListDetail_title">
      <div>信息资讯 > {{ type }}</div>
      <!-- <div class="newsListDetail_title_deep">商品详情</div> -->
    </div>
    <div class="newsListDetail_line"></div>
    <div class="newsListDetail_content">
      <div class="newsListDetail_content_detail">
        <div class="newsListDetail_content_detail_head">
          <div class="newsListDetail_content_detail_head_title">
            <div class="newsListDetail_content_detail_head_title_name">
              {{ info.newsTitle }}
            </div>
            <div class="newsListDetail_content_detail_head_title_line"></div>
            <div class="newsListDetail_content_detail_head_title_time">
              发布时间：{{ info.year }}.{{ info.day }}
            </div>
          </div>
        </div>
        <div
          class="newsListDetail_content_detail_img"
          v-html="info.newsContent"
        ></div>
      </div>
      <div class="newsListDetail_content_detail_right">
        <div class="newsListDetail_content_list">
          <div class="newsListDetail_content_list_title">
            文章推荐
          </div>
          <div class="newsListDetail_content_list_line"></div>
          <ul class="newsListDetail_content_list_ul">
            <li class="moveTop"
              v-for="item in recommendList"
              :key="item.newsId"
              @click="toDetails(item)"
            >
              {{ item.newsTitle }}
            </li>
          </ul>
        </div>
        <div
          class="newsListDetail_content_list newsListDetail_content_relevance"
        >
          <div class="newsListDetail_content_list_title">
            相关文章
          </div>
          <div class="newsListDetail_content_list_line"></div>
          <ul class="newsListDetail_content_list_ul">
            <li
             class="moveTop"
              v-for="item in aboutList"
              :key="item.newsId"
              @click="toDetails(item)"
            >
              {{ item.newsTitle }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "newsListDetail",
  data() {
    return {
      id: "",
      info: {
        name: "关于《野山参粉》实物交付的通知",
        time: "2023-09-29",
      },
      type: "",
      recommendList: [],
      aboutList: [],
    };
  },
  mounted() {
    window.scroll(0, 0);
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.getInfo(this.id);
    this.getList(3); // 18 文章推荐
    this.getList(2); // 18 文章推荐
  },
  methods: {
    getInfo(id) {
      //获取独立的数据
      axios
        .get(`https://admin.zoneyu.cn/cms/api/app/news/${id}`)
        .then((res) => {
          this.info = res.data.data;
          const updateTime = new Date(this.info.publishTime);
          this.info.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          this.info.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
        });
    },
    getList(categoryId) {
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?status=PUBLISHED&current=1&size=5&websiteCategoryId=${categoryId}`,
        )
        .then(async (res) => {
          const content = res.data.data.list;
          if (categoryId == 2) {
            this.aboutList = content;
          }
          if (categoryId == 3) {
            this.recommendList = content;
          }
        });
    },
    toDetails(item) {
      this.id = item.newsId;
      this.getInfo(this.id);
    },
  },
};
</script>
<style scoped lang="scss">
.newsListDetail_bg {
  min-width: 1280px;
  img {
    width: 100%;
  }
}
.newsListDetail_title {
  font-size: 16px;
  font-weight: 500;
  color: #abaeb0;
  line-height: 22px;
  display: flex;
  width: 1280px;
  margin: 27px auto;
  .newsListDetail_title_deep {
    color: #565759;
  }
}
.newsListDetail_line {
  width: 100%;
  height: 1px;
  background: #f7f7f7;
  margin-bottom: 40px;
}
.newsListDetail_content {
  width: 1280px;
  margin: 0 auto 30px auto;
  display: flex;
  .newsListDetail_content_detail {
    width: 900px;
    .newsListDetail_content_detail_head {
      display: flex;
      .newsListDetail_content_detail_head_title_name {
        font-size: 22px;
        font-weight: bold;
        color: #1d1b19;
        line-height: 30px;
        letter-spacing: 1px;
      }
      .newsListDetail_content_detail_head_title_line {
        width: 100%;
        height: 1px;
        background: #f7f7f7;
        margin: 15px 0;
      }
      .newsListDetail_content_detail_head_title_time {
        font-size: 14px;
        font-weight: 400;
        color: #abaeb0;
        line-height: 20px;
      }
    }
    .newsListDetail_content_detail_tips {
      font-size: 22px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 30px;
      letter-spacing: 1px;
      margin-top: 32px;
    }
    .newsListDetail_content_detail_line {
      width: 100%;
      height: 4px;
      background: #9e1307;
      margin-top: 20px;
    }
    /deep/ .newsListDetail_content_detail_img {
      margin-top: 28px;
      img {
        width: 100%;
      }
    }
  }

  .newsListDetail_content_detail_right {
    .newsListDetail_content_list {
      width: 316px;
      max-height: 378px;
      background: #f7f7f7;
      padding: 21px 18px 10px 18px;
      margin-left: 24px;
      .newsListDetail_content_list_title {
        font-size: 16px;
        font-weight: bold;
        color: #4d5461;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .newsListDetail_content_list_line {
        width: 100%;
        height: 1px;
        background: #e5e5e5;
        margin-bottom: 10px;
      }
      .newsListDetail_content_list_ul {
        padding: 0;
        li {
          list-style-type: none;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #4d5461;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
        li:before {
          content: "";
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          background: #565759;
          vertical-align: middle;
          margin-right: 12px;
        }
      }
    }
    .newsListDetail_content_relevance {
      margin-top: 24px;
    }
  }
}
</style>
